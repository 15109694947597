.bem_banner {
  color: #fff;
  position: relative;
  
  &:visited {
    color: #fff;
  }

  &__headline {
    display: block;
    font-size: 25px;
    font-weight: 400;
  }

  &__link {
    border-bottom: 2px solid;
    color: $text-color-invert;
    visibility: hidden;

    &:hover {
      border-bottom: none;
      color: $text-color-invert;
    }
  }

  &:hover &{

    &__link {
      visibility: visible;
    }
  }
}