#sync1.owl-carousel {
  margin-bottom: 10px;

  .owl-buttons div {
    border-radius: 0;
    width: 70px;
    height: 70px;
    border: 0;
  }

  .owl-prev{
    background: url(../img/melpd/slider_arrow_left.png) no-repeat center center;
    box-shadow: none;
    left: -23px;

    &:hover {
      box-shadow: none;
    }

    &:before {
      content: '';
    }
  }

  .owl-next {
    background: url(../img/melpd/slider_arrow_right.png) no-repeat center center;
    box-shadow: none;
    right: -23px;

    &:hover {
      box-shadow: none;
    }

    &:before {
      content: '';
    }
  }
}

#sync2.owl-carousel {

  .item {
    //width: 120px;
    margin-right: 15px;

    @media (max-width: 1200px) {
      margin-right: 5px;
    }

    @media (max-width: 992px) {
      margin-right: 2px;
    }
  }

  .owl-item {
    @media (max-width: 992px) {
      padding: 2px;
    }
  }
}