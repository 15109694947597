.product-advantages {
  background: #fff url(../img/melpd/shtender-background.jpg) no-repeat;
  background-position: 6% center;
  padding-top: 129px;
  padding-bottom: 93px;

  @media (max-width: 1350px) {
    background-position: -19% center;
  }

  @media (max-width: 1150px) {
    background-position: -56% center;
  }

  @media (max-width: 992px) {
    background: none;
  }

  &__headline {
    margin-bottom: 65px;
  }

  &__list {
    list-style: none;
    margin: 0;
    padding: 0;
    padding-left: 18px;

    li {
      color: $text-color;
      font-size: 18px;
      line-height: 25px;
      background: url(../img/melpd/list-marker.png) left 14% no-repeat;
      padding-left: 40px;
      margin-bottom: 28px;
    }
  }
}