.bem_headline {

  &_h2 {
    color: $text-color;
    font-size: 40px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 2px;

    &:first-line {
      color: $text-light-color;
      font-weight: 400;
      font-size: 0.8em;
    }
  }

  &_h3 {
    color: $text-color;
    font-size: 25px;
    font-weight: 700;
    letter-spacing: 2px;
  }

  &_align_center {
    text-align: center;
  }

  &_line_bottom {
    &:after {
      content: '';
      display: block;
      width: 50px;
      height: 5px;
      background: $text-color;
      margin-top: 20px;
    }
  }

  &_line_center-bottom {

    &:after{
      background: $text-color;
      content: '';
      display: block;
      width: 50px;
      height: 5px;
      margin: 0 auto;
      margin-top: 25px;
    }
  }

  &_color_invert {
    color: $text-color-invert;
  }
}