.bem_color {

  &__select {
    background: url(../img/melpd/bem_color__select_bg.png) center center no-repeat;
    display: block;
    height: 12px;
    visibility: hidden;
    + [type="radio"]:checked {
      visibility: visible;
    }
  }


  &__item {
    margin-right: 15px;
    display: inline-block;
  }

  &__item:hover &{

    &__select {
      visibility: visible;
    }
  }

  &__variant {
    display: inline-block;
    border: 1px solid $text-light-color;
    border-radius: 50%;
    width: 46px;
    height: 46px;


    &:last-child {
      margin-right: 0;
    }
  }

  &__checkbox {
    display: none;
  }

  &__checkbox:checked + & {

    &__select {
      visibility: visible;
    }
  }
}