.demo_changer{ 
    z-index: 99999; 
    position: fixed;
    left: -230px;
    top: 50px; 
}

.demo_changer .styleswitch,
.demo_changer .patternswitch,
.demo_changer .bgimageswitch {
    margin: 0 3px 3px 0;
    display: inline-block;
    background: red;
    width: 25px;
    height: 25px;
    border-radius: 3px;
}


.demo_changer .line {
    border-bottom: 1px solid #efefef;
    clear: both;
    margin: 20px !important;
}


.demo_changer span a {color:#7F7F7F;
	font-weight: 300;
	padding: 0;
	font-size:11px;
	margin: 20px;
	text-align: center;
	line-height: 1.5;
}


.demo_changer span a:hover,
.demo_changer span a:active {opacity:1; color:#7F7F7F;
}


.demo_changer .demo-icon{
    /*background: url(../img/switcher/color.png) no-repeat scroll 4px 0 #fff;*/
    /*box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);*/
    cursor: pointer;
    float: right;
    height: 45px;
    width: 45px;
    text-align:  center;
    line-height: 45px;
    background: #424242;
    font-size: 28px;
    color: white;
    z-index:  9999;
}

.demo_changer .form_holder p {color:#7F7F7F;}
.demo_changer .form_holder {
	background: #fff;
    float: right;
	box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
    width: 230px;
}

.demo_changer .form_holder p{
    padding:0 20px;
    font-size: 11px;
}

.demo_changer .form_holder input{
    width: 55px;
	border-radius: 0 10px 10px 0;
	box-shadow: none;
}

.demo_changer .form_holder form{
    padding: 0 20px;
}

.demo_changer .color_display_2 {
   clear: both;
    color: #FFFFFF;
    display: inline-block;
    font-size: 12px;
	width:31px !important;
	height:31px;
    margin-bottom: 7px;
    padding: 4px 10px;
    text-align: center;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.3);
    text-transform: uppercase;
	box-shadow: 0 1px 0 rgba(255, 255, 255, 0.3) inset, 0 0 2px rgba(255, 255, 255, 0.3) inset, 0 1px 2px rgba(0, 0, 0, 0.29);
}

.demo_changer .color_display_2 a{
    color: #fff;
    text-decoration: none;
}

.color_display_2 a:focus,
.color_display_2 a:hover {
    color: #ededed !important;
    text-decoration: none;
}


.demo_changer .predefined_styles{
    padding:0 20px;
}



@media only screen and (max-width: 1024px) {
.demo_changer{
    display: none;
}
} 



