/*+clearfix {*/
.cart_form:after
{
	content: ".";
	display: block;
	height: 0;
	clear: both;
	visibility: hidden;
	font-size: 0;
}
.cart_form
{
	display: inline-block;
}
.cart_form
{
/*\*/
	display: block;
/**/
	-height: 1px;
}
/*+}*/
.form INPUT[type="text"], .form INPUT[type="password"]
{
	width: 100%;
	font-size: 18px;
}
.form
{
	width: 400px;
	margin-bottom: 20px;
}
/*[clearfix]*/.cart_form
{
	width: auto;
	margin-top: 20px;
	margin-bottom: 20px;
}
.cart_form .btn-primary
{
	float: right;
	margin-top: 15px;
}
.form TEXTAREA
{
	width: 100%;
	height: 100px;
	font-size: 18px;
}
.form LABEL
{
	display: block;
	font-size: 14px;
}
.form INPUT[type="text"]
{
	display: block;
	margin-bottom: 10px;
}
/*@group Комментарии*/
.comment_list
{
	margin-top: 15px;
	list-style: none;
}
.comment_list LI
{
	padding-bottom: 15px;
}
.comment_header
{
	font-size: 18px;
}
.comment_header I
{
	font-weight: normal;
	font-style: normal;
	color: #878787;
	font-size: 13px;
}
/*@end — Комментарии
@group Форма отправки комментария*/
.comment_form
{
	background-color: #F3F3F3;
	border: 1px solid #E0E0E0;
	padding: 20px;
	margin-top: 20px;
	width: 90%;
	overflow: hidden;
}
.comment_form H2
{
	margin-bottom: 0px;
}
.comment_form .comment_textarea
{
	width: 100%;
	height: 100px;
	font-size: 12px;
}
.comment_form LABEL
{
	display: block;
	float: left;
	width: 100px;
	font-size: 18px;
	margin-top: 15px;
}
.comment_form .input_name
{
	font-size: 16px;
	width: 250px;
	margin-top: 15px;
}
.input_captcha, .cart_form .input_captcha
{
	float: left;
	width: 150px !important;
	font-size: 24px;
	font-weight: bold;
	text-transform: uppercase;
	margin-top: 15px;
	height: 36px;
}
.comment_form .captcha, .cart_form .captcha
{
	float: left;
	display: block;
	margin-top: 15px;
	margin-right: 10px;
}
.comment_form .button, .feedback_form .button, .register_form .button, .login_form .button
{
	float: right;
	display: block;
	margin-top: 10px;
	margin-right: 0px;
}
/*@end — Форма отправки комментария
@group Форма отправки обратной связи*/
.feedback_form
{
	margin-top: 20px;
	width: auto;
	overflow: hidden;
}
.feedback_form .input_captcha, .register_form .input_captcha
{
	float: left;
	width: 150px;
	font-size: 24px;
	font-weight: bold;
	text-transform: uppercase;
	margin-top: 15px;
	height: 36px;
}
.captcha, .register_form .captcha
{
	float: left;
	display: block;
	margin-top: 15px;
	margin-right: 10px;
}
.feedback_form .button_send
{
	font-size: 18px;
	float: right;
	margin-top: 25px;
}
/*@end — Форма отправки комментария
@group Корзина*/
#purchases
{
	width: 100%;
}
#purchases TR
{
	border-top: 1px solid #D5D5D5;
	height: 70px;
}
#purchases TH
{
	vertical-align: top;
	padding-top: 10px;
	font-size: 18px;
	font-weight: normal;
}
#purchases .image
{
	width: 50px;
	text-align: center;
}
#purchases .image A
{
	border: 1px solid #E0E0E0;
	background-color: #FFFFFF;
	margin: 0 7px 7px 0;
	display: table-cell;
	vertical-align: middle;
	text-align: center;
	width: 50px;
	height: 50px;
}
#purchases .name
{
	padding-left: 10px;
	padding-right: 10px;
	text-align: left;
}
#purchases TD.name
{
	font-size: 14px;
}
#purchases TD .download_attachment
{
	white-space: nowrap;
	color: #3B8500;
	padding: 5px 10px 7px;
	border-bottom-color: 1px dotted green;
	background-color: #CCFF72;
	line-height: 30px;
}
#purchases .price
{
	padding-left: 5px;
	padding-right: 5px;
	white-space: nowrap;
	text-align: right;
}
#purchases TD.price
{
	font-size: 14px;
}
#purchases .remove
{
	padding-left: 15px;
	text-align: right;
}
#purchases .amount
{
	font-size: 14px;
	padding-left: 5px;
	padding-right: 5px;
}
#purchases .amount SELECT
{
	font-size: 12px;
}
#purchases .coupon .name
{
	font-size: 14px;
}
#purchases .coupon .name INPUT.coupon_code
{
	width: 200px;
}
#purchases .coupon .name INPUT
{
	font-size: 16px;
}
UL#deliveries
{
	margin-top: 20px;
	margin-bottom: 20px;
	background-color: #FFFFFF;
	border: 1px solid #E0E0E0;
	padding: 20px 20px 0;
	list-style: none;
}
UL#deliveries LI
{
	margin-bottom: 20px;
}
UL#deliveries LI DIV.checkbox
{
	float: left;
	margin-top: 0px;
	margin-left: -15px;
	margin-right: 5px;
}
UL#deliveries LI H3, UL#deliveries LI .description
{
	display: block;
	margin-left: 25px;
}
/*@end
@group Кабинет*/
#orders_history
{
	list-style: none;
}
#orders_history LI
{
	margin-bottom: 10px;
}
#orders_history LI A
{
	font-size: 16px;
}
/*@end — Кабинет
@group Детали заказа*/
TABLE.order_info
{
	margin-right: 20px;
	margin-bottom: 20px;
	background-color: #F9F9F9;
}
TABLE.order_info TD
{
	padding: 10px;
	border: 1px dotted #E0E0E0;
	font-size: 14px;
}
.checkout_button
{
	padding: 10px 20px;
	border: 1px solid #51A400;
	background-color: #D3FFA9;
	color: #2E5E00;
	font-size: 14px;
}
/*@end
Сообщение с ошибкой*/
.message_error
{
	clear: both;
	padding: 10px 20px;
	margin-bottom: 15px;
	margin-top: 10px;
	overflow: hidden;
	color: #FF0000;
	background-color: #FFCACA;
	border: 1px dotted #FF4545;
}
/*@end*/
button.btn-sm {  background: transparent;}
.product-meta select {margin-top: 10px;}
.shopping-cart-box H4 {color: #fff;}
.fright
{
	float: right;
}
.selected a {font-weight: bold;}
#back_to_top {
  display: none;
  position: fixed;
  z-index: 1000;
  bottom: 40px;
  right: 40px;

}
#back_to_top a {
  width: 45px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  outline: none;
  border-radius: 50%;
  opacity: 0.5;
  filter: alpha(opacity=50);
  background: #333;
}
#back_to_top a:hover {
  opacity: 0.8;
  filter: alpha(opacity=80);
}