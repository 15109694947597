.bem_delivery-city {
  display: inline-block;
  width: 24%;
  vertical-align: top;
  text-align: center;

  @media (min-width: 992px) and (max-width: 1200px) {
    width: 49%;
  }

  @media (max-width: 600px) {
    width: 49%;
  }

  @media (max-width: 448px) {
    width: 100%;
  }

  &__name {
    font-size: 13px;
    font-weight: 700;
    font-family: $font-family;
    margin-bottom: 13px;
  }
  
  &__interval {
    background: #a5a5a5;
    color: $text-color-invert;
    font-family: $font-family;
    font-size: 15px;
    font-weight: 700;
    border-radius: 4px;
    padding-top: 12px;
    padding-bottom: 7px;
    margin-left: 5px;
    margin-right: 5px;

    &:first-line {
      font-size: 26px;
      font-weight: 700;
    }
  }

  &__coast {
    color: $text-color;
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 18px;
  }

  &__note {
    color: $text-light-color;
    font-size: 15px;
    margin-top: 20px;
  }
}