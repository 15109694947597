.bem_options {
  text-align: center;
  padding-top: 60px;
  padding-bottom: 60px;

  &__head {
    box-sizing: border-box;
    background: url(../img/melpd/bem_options__head_bg.png) center center no-repeat;
    width: 185px;
    height: 130px;
    padding-top: 20px;
    box-shadow: 0px 2px 98px -19px rgba(0, 0, 0, 0.7);
  }

  &__item {
    max-width: 195px;
    margin: 0 auto;
  }

  &__name {
    background: top center no-repeat;
    color: $text-color-invert;
    font-family: $headers-font-family;
    font-weight: 700;
    font-size: 25px;
    padding-top: 67px;

    &_icon {

      &_size {
        background-image: url(../img/melpd/bem_options__icon_size.png);
      }

      &_layers {
        background-image: url(../img/melpd/bem_options__ico_layers.png);
      }
      
      &_palit {
        background-image: url(../img/melpd/bem_options__icon_palit.png);
      }
    }
  }

  &__text {
    color: $text-light-color;
    font-size: 18px;
    margin-top: 30px;
  }
}