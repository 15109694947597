.bem_product-info {

  &__headline {
    margin: 0;
  }

  &__price {
    color: $text-color;
    font-family: $headers-font-family;
    font-weight: 700;
    font-size: 50px;
    line-height: 1em;
    margin-bottom: 74px;
  }

  &__colors {
    margin-bottom: 10px;
  }

  &__descr {
    max-width: 526px;

    p {
      font-size: 18px;
    }
  }

  &__variants {
    select {
      padding: 10px 30px;
      min-width: 240px;
    }
  }
}