.bem_delivery-info {
  padding-top: 30px;
  padding-bottom: 30px;

  &__item {
    background: $text-color-invert;
    padding: 35px 28px;
    position: relative;
    z-index: 2;
  }

  &__left-card {
    position: relative;

    &:before {
      content: url(../img/melpd/bem_delivery-info__left-col-bg.jpg);
      display: block;
      position: absolute;
      z-index: 0;
      margin-left: -163px;
      top: 63px;
      
      @media (max-width: 1431px) {
        display: none;
      }
    }
  }

  &__right-card {
    position: relative;

    &:after {
      content: url(../img/melpd/bem_delivery-info__right-card_bg.png);
      display: block;
      position: absolute;
      z-index: 0;
      right: 0;
      margin-right: -115px;
      top: 63px;

      @media (max-width: 1431px) {
        display: none;
      }
    }
  }
}