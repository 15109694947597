$font-family: 'PT Sans', Arial, helvetica, sans-serif;
$headers-font-family: 'Bebas Neue', Arial, helvetice, sans-serif;
$original-color: #2a8fbd;
$new-color: #d44040;
$theme-color: $new-color;
$text-light-color: #7c7c7c;
$text-color: #333;
$text-color-invert: #fff;
$background-color: #fff2f2;
$main-background-color: #fff;
$border-color: #d9d9d9;