.section-header {
  border-left: $theme-color 5px solid;
  padding-left: 14px;
  padding-top: 10px;
  padding-bottom: 29px;
  margin-left: 15px;

  &__headline {
    margin: 0;
    margin-bottom: 30px;
  }

  &__descr {
    padding: 0;
    color: $text-color;
    font-size: 18px;
    font-weight: 400;
  }
}