.b1c-form {
  border-radius: 0!important;
}

.b1c-form .b1c-tl {
  background: $theme-color!important;
  border-right: 0!important;
}

.b1c-submit {
  color: $text-color-invert!important;
  background: $theme-color!important;
  box-shadow: 0 3px 0 darken($theme-color, 5%)!important;
}

.b1c-submit:hover {
  background: darken($theme-color, 10%)!important;
}
