.bem_button {
  color: $text-color-invert;
  border: 0;
  display: inline-block;
  border-radius: 40px;
  background: $text-color;
  background-position: 13% center;
  background-repeat: no-repeat;
  font-size: 15px;
  line-height: 60px;
  padding: 0 38px;
  padding-left: 60px;
  min-width: 240px;
  margin: 10px 0;
  text-decoration: none;

  &:hover {
    color: $text-color-invert;
    text-decoration: underline;
  }

  &_type_add-to-cart {
    background-image: url(../img/melpd/add-to-cart_ico.png);
  }

  &_type_one-click {
    background-color: $theme-color;
    background-image: url(../img/melpd/one-click_ico.svg);
  }
}