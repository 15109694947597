.about-zakaz {
  background: url(../img/melpd/about-zakaz__background.jpg) center center no-repeat;
  background-size: contain;
  padding-top: 110px;
  padding-bottom: 85px;
  text-align: center;
  
  @media (max-width: 650px) {
    background: none;
  }  

  &__headline {
    color: $text-color;
    font-size: 25px;
    font-weight: 700;
    text-transform: uppercase;
    margin: 0;
    margin-bottom: 19px;
  }

  &__link {
    color: $text-color;
    text-transform: uppercase;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}