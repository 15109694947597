.bem_delivery-card {
  box-sizing: border-box;
  min-height: 345px;

    &__headline {
      font-family: $headers-font-family;
      text-align: center;
      color: $theme-color;
      font-size: 25px;
      font-weight: 700;
      margin: 0;
      margin-bottom: 33px;
    }

    &__pay-type {
      display: inline-block;
      width: 32%;
      margin-bottom: 20px;

      @media (min-width: 992px) and (max-width: 1200px) {
        width: 49%;
      }

      @media (max-width: 650px) {
        width: 49%;
      }

      @media (max-width: 448px) {
        width: 100%;
      }

      img {
        width: auto;
        max-width: 100%;
      }
    }

    &__content {
      &_padding {
        padding-top: 30px;
      }
    }

  &__note {
    color: $theme-color;
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    margin-top: 43px;
  }
}